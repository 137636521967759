@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 90px 0;

  .title {
    text-align: center;
    color: $colored;
    margin-bottom: 60px;
  }

  .marqueeContainer {
    margin-bottom: 30px;
  }

  .merqueeItem {
    flex-shrink: 0;
    border-radius: 10px;
    background: rgba(0, 102, 139, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 0 20px;
    width: 280px;
    height: 60px;

    img {
      width: auto;
      height: auto;
    }
  }

  @media screen and (max-width: $land-767) {
    padding: 40px 0;
    
    .merqueeItem {
      width: 180px;
      height: 40px;
      
      img {
        height: 100%;
      }
    }
  }
}