@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 90px 0;

  .title {
    text-align: center;
    color: $colored;
    margin-bottom: 40px;
  }

  .list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px 20px;

    &Item {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      border-radius: 10px;
      background: #E5F0F3;
      padding: 20px 40px;
      min-height: 280px;

      &Title {
        margin: 40px 0 20px;
        font-weight: 500;
        color: $colored;
      }

      img {
        width: auto;
      }
    }
  }

  .text {
    color: rgba(0, 0, 0, 0.70);
  }

  .center {
    text-align: center;
  }

  .slider {
    &Wrap {
      width: 100%;
      margin-top: 20px;
    }
    &Btns {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      position: relative;
      z-index: 99;
      margin-top: 25px;
    }

    &Btn {
      width: 45px;
      height: 45px;
      flex-shrink: 0;
      border-radius: 5px;
      background-color: $colored;

      img {
        width: 75%;
        height: 75%;
      }

      &.sliderBtnPrev {
        transform: rotate(-180deg);
      }

      &.inactive {
        opacity: 0.5;
      }
    }

    &Item {
      border-radius: 10px;
      background: #E5F0F3;
      img {
        width: auto;
      }
    }
  }

  @media screen and (min-width: $land-769) {
    .list {
      grid-template-columns: repeat(3, 1fr);

      &Item {
        &Title {
          padding-right: 25px;
        }
      }
    }
  }

  @media screen and (max-width: $land-768) {
    padding: 40px 0;
    
    .list {
      &Item {
        padding: 20px 15px;
      }
    }
  }
}