@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 0;
  min-height: 750px;
  height: 100%;
  background: url("../../../assets/images/about/bg.png") no-repeat center;
  background-size: cover;
  padding-bottom: 80px;

  .container {
    min-height: 650px;
    height: 100%;
    padding: 160px 20px 80px;
  }

  .left {
    height: 100%;
    min-height: 500px;
    flex: 0 0 auto;
    position: relative;
    z-index: 2;
    overflow: hidden;
  }

  .title {
    color: #fff;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -1.4px;
  }

  .text {
    color: #fff;
    opacity: 0.7;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    margin-top: 40px;
  }
  
  @media screen and (min-width: $land-768) {
    .left {
      max-width: 663px;
      width: 50%;
    }
  }
  
  @media screen and (max-width: $land-767) {
    background: url("../../../assets/images/about/bg-mob.png") no-repeat center;
    padding: 115px 0 40px;
    min-height: 750px;
    background-size: cover;

    .container {
      padding: 0 20px;
    }

    .title {
      margin-bottom: 14px;
      letter-spacing: -0.8px;
    }
    .text {
      margin-top: 20px;
    }
  }
}