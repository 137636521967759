$colored: #00668B;
$seccolor: #F6FF82;
$maintext: rgba(0, 0, 0, 0.70);
$textbg: #181818;

$opensans: "Open Sans", sans-serif;
$inter: "Inter", sans-serif;
$lato: "Lato", sans-serif;

$max-width: 1920px;
$main-width: 1220px;
$land-992: 992px;
$land-769: 769px;
$land-768: 768px;
$land-767: 767px;
$minwidth: 375px;