@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 90px 0;

  .row {
    display: flex;

    .text:first-child {
      margin-top: 0;
    }
  }

  .title {
    color: $colored;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -1.4px;
    margin-bottom: 40px;
  }

  .text {
    color: rgba(0, 0, 0, 0.70);
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    margin-top: 20px;
  }
  
  @media screen and (min-width: $land-768) {
    .item {
      width: 50%;
      flex: 1 1 auto;
    }

    .row {
      &:not(:last-child) {
        margin-bottom: 125px;
      }
      .paddL {
        padding-left: 10%;
      }

      .paddR {
        padding-right: 10%;
      }
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .container {
      display: flex;
      flex-direction: column;
      row-gap: 120px;
    }

    .row {
      flex-direction: column;
      gap: 60px;
      margin: 0;

      &:first-child {
        flex-direction: column-reverse;
      }
    }

    .title {
      margin-bottom: 14px;
      letter-spacing: -0.8px;
    }
    .text {
      margin-top: 20px;
    }
  }
}