@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 90px 0;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    overflow: hidden;
  }

  .title {
    color: $colored;
    margin-bottom: 20px;
  }

  .subtitle {
    color: rgba(0, 0, 0, 0.70);
  }

  .text {
    margin-top: 40px;
    color: rgba(0, 0, 0, 0.70);
  }

  @media screen and (min-width: $land-769) {
    .image {
      max-width: 660px;
      width: 50%;
    }

    .item {
      max-width: 550px;
      width: 45%;
    }
  }

  @media screen and (max-width: $land-768) {
    padding: 40px 0;
    
    .container {
      flex-direction: column;
    }
  }
}