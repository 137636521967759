@import './_variables';

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.font-64-36 {
    font-family: $lato;
    font-size: 64px;
    font-weight: 600;
    line-height: 120%; 
    letter-spacing: -2.24px;
}

.font-40-24, .font-40-32 {
    font-family: $lato;
    font-size: 40px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -1.4px;
}

.font-40-25 {
    font-family: $lato;
    font-size: 40px;
    font-weight: 700;
    line-height: 150%; 
    font-style: italic;
}

.font-32-24 {
    font-family: $lato;
    font-size: 32px;
}

.font-24-16, .font-24-20 {
    font-size: 24px;
    font-weight: 500;
    line-height: 150%; 
}

.font-20-16 {
    font-family: $inter;
    font-size: 20px;
    line-height: 150%;
    font-weight: 500;
}

.font-16-14 {
    font-family: $inter;
    font-size: 16px;
    line-height: 150%;
    font-weight: 300;
}

.font-14 {
    font-family: $inter;
    font-size: 14px;
    line-height: 150%;
    font-weight: 300;
}

@media screen and (max-width: $land-767) {
    .font-64-36 {
        font-size: 36px;
    }

    .font-40-25 {
        font-size: 25px;
    }

    .font-40-32 {
        font-size: 32px;
    }

    .font-40-24, .font-32-24 {
        font-size: 24px;
    }

    .font-25-16, .font-24-16, .font-24-20, .font-20-16 {
        font-size: 16px;
    }
    
    .font-16-14, .font-17-14 {
        font-size: 14px;
    }
}