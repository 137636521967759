@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 90px 0;

  .container {
    display: flex;
    align-items: flex-start;
    gap: 8%;
    justify-content: space-between;
  }

  .title {
    color: $colored;
    margin-bottom: 60px;
    font-weight: 600;
    letter-spacing: -1.12px;
  }

  .list {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 40px;

    &Item {
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.20);
        padding: 0 0 40px;
      }

      &Title {
        margin: 0 0 20px;
        font-weight: 500;
        color: $colored;
      }

      .icon {
        flex-shrink: 0;
        width: 120px;
        height: 120px;
        border-radius: 10px;
        background: #E5F0F3;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: auto;
      }
    }
  }

  .text {
    color: rgba(0, 0, 0, 0.70);
  }

  @media screen and (min-width: $land-769) {
    .left {
      max-width: 437px;
      width: 35%;
      flex-shrink: 0;
    }

    .list {

      &Item {
        display: flex;
        align-items: flex-start;
        gap: 40px;
      }
    }
  }

  @media screen and (max-width: $land-768) {
    padding: 40px 0;

    .container {
      flex-direction: column;
      gap: 30px;
    }
    
    .list {
      gap: 20px;

      &Item {
        flex-direction: column;
        gap: 25px;
      }

      .icon {
        width: 80px;
        height: 80px;

        img {
          width: 52px;
          height: 52px;
        }
      }
    }
  }
}