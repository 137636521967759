@import '../../../styles/variables';

.wrap {
    position: relative;
    width: 100%;
    padding: 90px 0;

    .container {
        background-color: $colored;
        border-radius: 10px;
    }

    @media screen and (min-width: $land-768) {
        .container {
            padding: 20px 20px 0;
            max-width: 1350px;
        }

        .content {
            max-width: 700px;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: $land-767) {
        padding: 40px 20px;

        .container {
            padding: 40px 20px 60px;
        }
    }
}