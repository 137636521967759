@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 0;
  min-height: 650px;
  height: 100%;
  background: url("../../../assets/images/homepage/bg.png") no-repeat center;
  background-size: cover;
  padding-bottom: 80px;

  .container {
    min-height: 650px;
    height: 100%;
    padding: 130px 20px 0;
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: flex-start;
  }

  .left {
    flex: 0 0 auto;
    position: relative;
    z-index: 2;
    overflow: hidden;
  }

  .title {
    color: #fff;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    text-transform: capitalize;
  }

  .subtitle {
    color: #FFF;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.4px;
    margin-top: 10px;
  }

  .subtext {
    color: #FFF;
    font-style: italic;
    font-weight: 400;
    margin-top: 40px;
  }

  .text {
    color: rgba(255, 255, 255, 0.70);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 60px;
  }
  
  @media screen and (min-width: $land-768) {
    .left {
      max-width: 663px;
      width: 50%;
      padding-top: 50px;
      height: 100%;
      min-height: 500px;
    }

    .right {
      max-width: 552px;
      flex-shrink: 0;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.80);
      // backdrop-filter: blur(8px);
      z-index: 9;
    }
  }
  
  @media screen and (max-width: $land-767) {
    background: url("../../../assets/images/homepage/bg-mob.png") no-repeat center;
    padding: 130px 0 40px;
    min-height: 950px;
    background-size: cover;

    .container {
      padding: 0 20px;
      flex-direction: column;
      gap: 80px;
    }

    .title {
      margin-bottom: 14px;
    }
    .subtitle {
      margin-top: 0;
    }
    .subtext {
      margin-top: 20px;
    }
    .text {
      margin-top: 40px;
    }
  }
}