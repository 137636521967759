@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 100px 0;

  .container {
    position: relative;
  }

  .topBlock {
    display: flex;
    align-items: stretch;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 60px;
  }

  .title {
    color: $colored;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -1.12px;
  }

  .slider {
    &Wrap {
      width: 100%;
      margin-top: 20px;
    }
    &Btns {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      position: relative;
      z-index: 99;
      margin-top: 25px;
    }

    &Btn {
      width: 45px;
      height: 45px;
      flex-shrink: 0;
      border-radius: 5px;
      background-color: $colored;

      img {
        width: 75%;
        height: 75%;
      }

      &.sliderBtnPrev {
        transform: rotate(-180deg);
      }

      &.inactive {
        opacity: 0.5;
      }
    }

    &Item {
      max-width: 435px;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.20);
      padding: 20px 40px;

      .top {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
  }

  .slide {
    &Title {
      color: rgba(0, 0, 0, 0.70);
      line-height: normal;
      margin-bottom: 10px;
    }

    &Text {
      color: rgba(0, 0, 0, 0.70);
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
      margin-top: 20px;
    }
  }

  .imgWrap {
    width: 60px;
    height: 60px;

    img {
      width: 100%;
    }
  }

  .stars {
    width: 94px;
    height: 18px;
  }
  
  @media screen and (min-width: 769px) {
    .slider{
      &Btns {
        justify-content: flex-end;
        position: absolute;
        top: 0;
        right: 20px;
        margin-top: 0;
      }

      &Wrap {
        margin-top: 60px;
      }
    }
  }
  
  @media screen and (max-width: $land-768) {
    padding: 40px 0;

    .container {
      flex-direction: column;
    }

    .title {
      font-size: 32px;
    }

    .blockText {
      max-width: unset;
    }

    .slider {
      &Wrap {
        margin-top: 20px;
      }
    }

    .slide {
      &Title {
        margin-bottom: 13px;
      }
    }
  }
}