@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 120px 0 90px;

  .title {
    text-align: center;
    color: $colored;
    line-height: 140%;
    letter-spacing: -1.4px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .flex {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .center {
    text-align: center;
    width: 100%;
  }

  .mart {
    margin-top: 40px;
  }

  .list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 40px;

    &Item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 10px;
      background: #E5F0F3;
      padding: 20px 40px;
      min-height: 152px;
      gap: 20px;

      &Title {
        color: $colored;
        font-weight: 600;
        letter-spacing: -1.12px;
      }

      &Icon {
        width: 65px;
        height: 44px;
        flex-shrink: 0;

        img {
          width: 100%;
        }
      }

      &.row {

        .title {
          margin: 0;
        }

        .listItemIcon {
          width: auto;
          max-width: 156px;

          img {
            width: 100%;
          }
        }

        .flex {
          flex-direction: column;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }

  .text {
    color: rgba(0, 0, 0, 0.70);
  }

  .centered {
    text-align: center;
  }

  @media screen and (min-width: $land-769) {
    .title, .text {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    .mart {
      margin-top: 60px;
    }

    .list {
      grid-template-columns: repeat(3, 1fr);
      margin-top: 60px;

      &Item.row {
        grid-column: span 3;
        flex-direction: row;
        justify-content: space-between;

        .listItemIcon {
          max-width: unset;
        }

        & > div {
          width: 33%;
        }
      }
    }
  }

  @media screen and (max-width: $land-768) {
    padding: 110px 0 40px;
    
    .list {
      &Item {
        padding: 12px;

        &Icon {
          width: 45px;
          height: 30px;
          border-radius: 5px;
        }
      }
    }
  }
}