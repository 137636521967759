@import '../../../styles/_variables';

.footer {
  position: relative;
  width: 100%;
  padding: 0;
  background-color: #fff;

  &Content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 50px 53px 35px 20px;
    border-radius: 30px;
  }

  .leftSide {
    display: flex;
    align-items: flex-start;
    column-gap: 43px;
    width: 710px;
    flex-shrink: 0;
  }

  .logoWrap {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 120px;
    flex-shrink: 0;

    .logo {
      height: 40px;
      flex-shrink: 0;
      z-index: 2;
      width: auto;

      &Text {
        margin-left: -40px;
        z-index: 1;
      }
    }

    img {
      height: 100%;
    }
  }

  &Nav {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    .itemBlock {
      text-align: left;
    }
  }

  .navTitle {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.70);
    text-decoration: none;
    display: block;
  }

  .rightSide {
    width: 440px;

    .navTitle {
      font-size: 12px;
      text-align: left;
    }

    .text {
      margin: 10px 0;
      font-family: "Open Sans", sans-serif;
      font-weight: 300;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.70);
      text-align: left;
      line-height: 135%;

      p:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    .copyright {
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.70);
      text-align: right;
    }
  }
}

@media (max-width: 768px) {
  .footer {
    margin-top: 50px;
    padding: 0;

    &Content {
      flex-direction: column;
      row-gap: 30px;
      padding: 20px 0 55px;
      background-color: transparent;
      border-radius: 0;
    }

    .leftSide {
      column-gap: 43px;
      width: 100%;
      flex-direction: column;
      row-gap: 15px;
    }
  
    .logoWrap {
      // width: 40px;
      height: 40px;
      flex-shrink: 0;
    }

    .footerNav {
      row-gap: 15px;
    }

    .nav-title {
      font-size: 12px;
    }

    .rightSide {
      width: 100%;
  
      .navTitle {
        font-size: 12px;
      }
  
      .text {
        margin: 15px 0;
        font-size: 12px;
      }
  
      .copyright {
        font-size: 12px;
        text-align: left;
      }
    }
  }
}