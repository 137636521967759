@import '../../../styles/_variables';

.button {
  text-decoration: none;
  padding: 14px 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: $inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  border-radius: 40px;
  background: #FF7A7C;
  text-transform: capitalize;

  &.header {
    padding: 10px 20px;
    border-radius: 60px;
    font-weight: 400;
    font-size: 16px;
    border-radius: 60px;
  }

  &.black {
    background-color: #222;
    color: $maintext;
    border-radius: 3px;
  }

  @media (max-width: 768px) {
      letter-spacing: -0.2px;
  }
}