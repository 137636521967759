@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 90px 0;

  .container {
    border: 0px solid rgba(255, 255, 255, 0.30);
    background: #00668B;
    padding: 80px 10%;
  }

  .text {
    color: rgba(255, 255, 255, 0.70);
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 150%; 
  }

  .list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 20px;

    &Item {
      padding: 24px 30px;
      border-radius: 10px;
      background: #107AA1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      &Title {
        color: #fff;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: -0.84px;

        span {
          display: block;
          text-align: center;
          font-size: 14px;
          color: #FFF;
          font-family: $lato;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: -0.49px;
        }
      }

      &Text {
        color: #FFF;
        font-family: $lato;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1.68px;
      }
    }
  }

  @media screen and (min-width: $land-769) {
    .container {
      border-radius: 10px;
    }
    
    .list {
      grid-template-columns: repeat(2, 1fr);
      max-width: 900px;
      width: 75%;
      margin: 60px auto 0;
    }
  }

  @media screen and (max-width: $land-768) {
    padding: 40px 0;
  }
}