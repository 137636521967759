@import '../../../styles/variables';

.wrap {
    position: relative;
    gap: 20px;
    padding: 30px 20px;

    @media screen and (max-width: $land-768) {
        padding: 0;
    }

    .formWrap {
        width: 100%;

        @media screen and (max-width: $land-768) {
          padding: 0;
        }

        .title {
          color: #fff;
          text-align: center;
          font-family: $lato;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: -1.12px;

          @media screen and (max-width: $land-768) {
            font-size: 20px;
          }
        }

        .text {
          color: rgba(255, 255, 255, 0.70);
          text-align: center;
          font-family: $inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          max-width: 400px;
          margin: 10px auto 0;

          @media screen and (max-width: $land-768) {
            color: rgba(255, 255, 255, 0.70);
          }
        }

        .form {
          margin-top: 30px;

            .inputs {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
                gap: 12px 10px;
                margin: 40px 0 10px;
                justify-content: center;

                @media screen and (max-width: $land-768) {
                  display: flex;
                  flex-direction: column;
                }
            }

            .submit {
                display: flex;
                justify-content: center;
            }
        }
    }

    .labelTitle {
        margin-bottom: 3px;
        color: rgba(255, 255, 255, 0.70);
        font-family: $inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; 
        padding-left: 16px;
        display: inline-block;
        width: auto;
    }

    .input {
        width: 100%;
        height: 52px;
        padding: 14px 16px;
        border-radius: 40px;
        background: transparent;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.20);
        color: #fff;
        font-family: $inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        &::placeholder {
            color: rgba(255, 255, 255, 0.50);
        }

        &.error {
            border-color: red;
        }
    }

    .phone {
        padding: 0;
        border: none;
        text-align: left !important;

        .inputClass {
            width: 100% !important;
            height: 52px !important;
            border-radius: 10px !important;
            background: rgba(255, 255, 255, 0.20)!important;
            border: none!important;

            color: #fff !important;
            font-family: $inter;
            font-size: 16px !important;
            font-weight: 400 !important;
            line-height: 150% !important;

            &::placeholder {
                color: rgba(255, 255, 255, 0.50);
            }

            &.error {
                border-color: red !important;
            }
        }

        .buttonClass {
            background: transparent !important;
            border: none !important;

            &:hover {
                background: transparent;
            }

            :global(.selected-flag) {
                transition: all .1s;
                border-radius: 10px 0 0 10px !important;

                &:hover,
                &.open {
                    background: transparent !important;
                }

                &:global(.open) {
                    background: transparent !important;
                }
            }
        }

        .dropdownClass {
            color: #000 !important;
        }

        .searchClass {
            width: 100% !important;
            padding: 5px !important;

            input {
                border: none !important;
                width: 100%;
                margin-left: 0 !important;
            }
        }
    }

    .checkwrap {
        text-align: left;
        margin-bottom: 20px;

        input {
            border-radius: 2px;
            background: #3385A2;
            width: 12px;
            height: 12px;
            flex-shrink: 0;
        }

        .label {
            display: flex;
            align-items: center;
            color: #fff;
            font-family: $inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            gap: 8px;
            // margin-bottom: 15px;

            &.error {
                color: red;
            }

            @media screen and (max-width: $land-768) {
                color: #fff;
                font-size: 12px;
            }
        }
    }

    .button {
        text-decoration: none;
        display: inline-flex;
        padding: 14px 30px;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        background: #FF7A7C;
        color: #fff;
        font-family: $inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
            padding: 10px 20px;
            border-width: 1px;
            letter-spacing: -0.2px;
        }
    }
}

.main {
    .wrap {
        .formWrap {
            .title {
                color: $colored;
      
                @media screen and (max-width: $land-768) {
                  color: #fff;
                }
            }
    
            .text {
                color: rgba(0, 0, 0, 0.70);

                @media screen and (max-width: $land-768) {
                    color: #fff;
                }
            }
    
            .labelTitle {
                color: rgba(0, 0, 0, 0.70);
            }
        }
    
        .input {
            background: #FFF;
            color: #000;
    
            &::placeholder {
                color: rgba(0, 0, 0, 0.50);
            }
        }
    
        .phone {
            .inputClass {
                background: #fff!important;
                color: #000 !important;
    
                &::placeholder {
                    color: rgba(0, 0, 0, 0.50);
                }
            }
        }
    
        .checkwrap {
            input {
                background: #B4CDD7;
            }
    
            .label {
                color: $colored;
    
                @media screen and (max-width: $land-768) {
                    color: #fff;
                  }
            }
        }
    
        .popup{
            &Wrap {
                width: 100vw;
                height: 100vh;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.popup{
    &Wrap {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba($color: #000, $alpha: 0.9);
        z-index: 99999999;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        // pointer-events: none;
        gap: 30px;
        flex-direction: column;
        text-align: center;
    }

    &Content {
        padding: 50px;
        background-color: $colored;
    }

    &Text {
        font-size: 16px !important;
        line-height: 150%;
        color: #fff;
    }

    &Img {
        position: relative;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px auto;

        svg {
            transition: all .3s;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 100px;
            border: 4px solid #fff;
            transform: scale(1.5);
            transition: all .3s;
        }
    }
}

.close {
    position: absolute;
    z-index: 20;
    width: 20px;
    height: 20px;
    top: 140px;
    right: 140px;
    cursor: pointer;
    transition: all .1s;

    @media(max-width: 700px) {
        top: 20px !important;
        right: 20px !important;
    }

    @media(max-height: 500px) {
        top: 10px;
        right: 10px;
    }

    &:hover {
        transform: scale(0.95);
    }

    &::before {
        content: '';
        position: absolute;
        width: 28px;
        height: 2px;
        background: #fff;
        transform: rotate(45deg);
        top: 49%;
        left: -5px;
    }

    &::after {
        content: '';
        position: absolute;
        width: 28px;
        height: 2px;
        background: #fff;
        transform: rotate(-45deg);
        top: 49%;
        left: -5px;
    }
}